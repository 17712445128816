<template>
  <div id="app">
    <Loading></Loading>
    <router-view></router-view>
    <PoweredBy></PoweredBy>
    <Footer></Footer>
  </div>
</template>

<script>
import Loading from "./components/Loading.vue";
import PoweredBy from "./components/PoweredBy.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Loading,
    PoweredBy,
    Footer,
  },
  created: function() {
    this.$store.dispatch("getVendor");
  },
  mounted: function() {
    if (this.$store.state.authenticated) {
      this.$store.dispatch("connect").then((response) => {
        console.log(response);
        this.$store.dispatch("getOrder");
        this.$store.dispatch("getItems");
      });
    }
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@import "./assets/css/style.css";
</style>
