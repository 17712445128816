<template>
  <!-- Loading -->
  <b-row no-gutters v-if="$store.state.loading">
    <b-col class="text-center my-auto">
      <div class="text-center">
        <b-spinner key="dark" type="grow"></b-spinner>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style scoped>
.row {
  width: 100%;
  background-color: white;
  min-height: 100%;
  height: 100%;
  position: fixed;
  z-index: 1500;
}
</style>
