import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/login/:code",
    name: "Authenticate",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/login/",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/menu/",
    name: "Menu",
    component: () => import("../views/Menu.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/order/status/",
    name: "Status",
    component: () => import("../views/Status.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/payment/",
    name: "Payment",
    component: () => import("../views/Payment.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/logout/",
    name: "Logout",
    beforeEnter: (to, from, next) => {
      store.dispatch("logout");
      next("login");
    },
  },
  {
    path: "/vendor/invalid/",
    name: "InvalidVendor",
    component: () => import("../views/InvalidVendor.vue"),
  },
  {
    path: "/order/invalid/",
    name: "InvalidCredentials",
    component: () => import("../views/InvalidCredentials.vue"),
  },
  {
    path: "*",
    name: "404",
    component: () => import("../views/404.vue"),
    meta: {
      requireAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(to.name);
  if (to.meta.requireAuth && to.name != "Authenticate") {
    if (store.state.authenticated) {
      next();
    } else {
      next({ name: "Login" });
    }
  } else {
    next();
  }
});

export default router;
