<template>
  <!-- Powered By -->
  <b-container fluid>
    <b-row no-gutters>
      <b-col>
        <h6 class="font-weight-bold text-center mb-1 mt-3">
          Powered by
          <a
            href="https://www.tblside.com"
            class="font-weight-bold"
            target="_blank"
            style="color:black !important;"
          >
            <img
              style="height:3.5vh;vertical-align:bottom;"
              src="../assets/img/tblside-logomark-color.png"
            />
          </a>
        </h6>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "PoweredBy",
};
</script>
