import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import axios from "axios";
import Pusher from "pusher-js";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: {
    loading: false,
    authenticated: false,
    connected: false,

    vendor: {},
    order: {
      items: [],
    },
    menu: {
      items: [],
    },

    category: 1,
  },
  mutations: {
    setAuthenticated(state) {
      state.authenticated = true;
    },
    updateMenu(state, data) {
      state.menu.items = data;
    },
    updateOrder(state, data) {
      state.order = data;
    },
    addCategory(state) {
      state.category += 1;
    },
    removeCategory(state) {
      state.category -= 1;
    },
    setCategory(state, data) {
      state.category = data;
    },
    updateVendor(state, data) {
      state.vendor = data;
    },
    setLoading(state) {
      state.loading = true;
    },
    unsetLoading(state) {
      state.loading = false;
    },
    setConnected(state) {
      state.connected = true;
    },
    unsetConnected(state) {
      state.connected = false;
    },
    logout(state) {
      state.authenticated = false;
      state.connected = false;

      state.order = {
        items: [],
      };
      state.menu = {
        items: [],
      };
      state.category = 1;
    },
  },
  actions: {
    getVendor(context) {
      return new Promise((resolve, reject) => {
        if (!context.state.vendor.uuid) {
          const slug = window.location.host.split(".");
          axios
            .get(
              process.env.VUE_APP_VERIFY_VENDOR_URL + slug[0].toString() + "/"
            )
            .then((response) => {
              context.commit("updateVendor", response.data);
              resolve(context.state.vendor);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve(context.state.vendor);
        }
      });
    },
    getItems(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ITEMS_URL +
              "?vendor=" +
              context.state.vendor.uuid
          )
          .then((response) => {
            context.commit("updateMenu", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getOrder(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ORDER_URL +
              "?vendor=" +
              context.state.vendor.uuid
          )
          .then((response) => {
            context.commit("updateOrder", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    login(context, code) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_LOGIN_URL, {
            code: code,
            vendor: context.state.vendor.uuid,
          })
          .then((response) => {
            context.commit("logout");
            context.commit("setAuthenticated");
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    logout(context) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_LOGOUT_URL, {
            vendor: context.state.vendor.uuid,
          })
          .then((response) => {
            context.commit("logout");
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    connect(context) {
      //Left off building the connected feature only in data store. still have to add it to the components, and want to make sure I understand the promise reject and response on subscription succeeded or not.
      return new Promise((resolve, reject) => {
        //Pushing logging --> Do not include in production
        Pusher.logToConsole = true;

        Pusher.Runtime.createXHR = function() {
          var xhr = new XMLHttpRequest();
          xhr.withCredentials = true;
          return xhr;
        };

        var pusher = new Pusher("8efe2a56c26d0e5bcae3", {
          authEndpoint: process.env.VUE_APP_REALTIME_AUTH_URL,
          cluster: "mt1",
          auth: {
            params: { vendor: context.state.vendor.uuid },
          },
          forceTLS: true,
        });

        var channel_name = "private-" + context.state.order.uuid;
        var channel = pusher.subscribe(channel_name);

        channel.bind("pusher:subscription_succeeded", function(data) {
          context.commit("setConnected");
          resolve(data);
        });

        channel.bind("pusher:subscription_error", function(data) {
          context.commit("unsetConnected");
          reject(data);
        });

        pusher.connection.bind("connected", function() {
          context.commit("setConnected");
        });

        pusher.connection.bind("error", function() {
          context.commit("unsetConnected");
        });

        channel.bind("add-to-order", function(data) {
          console.log(data);
          context.commit("updateOrder", data);
        });
        channel.bind("delete-from-order", function(data) {
          console.log(data);
          context.commit("updateOrder", data);
        });
        channel.bind("submit-order", function(data) {
          console.log(data);
          context.commit("updateOrder", data);
        });
        channel.bind("update-order", function(data) {
          context.commit("updateOrder", data);
        });
      });
    },
  },
  plugins: [vuexLocalStorage.plugin],
});
